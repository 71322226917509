<script setup lang="ts">
import { toolsSubMenu } from '@/constants/main-menu'
import type { CarouselMobileCard } from '@/types/benchmark-app.type'
import Sidebar from '~/components/common/Sidebar.vue'
import StoreButtonsSection from '~/components/benchmark-app/StoreButtonsSection.vue'
import SubscriptionsAndAccessSection from '~/components/benchmark-app/SubscriptionsAndAccessSection.vue'
import Button from '~/components/common/Button.vue'
import BreadcrumbWithoutSidebar from '~/components/navigation/BreadcrumbWithoutSidebar.vue'
import FeaturedMobileCarousel from '@/components/FeaturedMobileCarousel.vue'
import RealTimeSupplyIcon from '@/assets/icons/benchmark-app/real-time-supply-chain-prices.svg'
import ComprehensiveIcon from '@/assets/icons/benchmark-app/comprehensive-market-insights.svg'
import PersonalizedIcon from '@/assets/icons/benchmark-app/personalized-experience.svg'
import QRCodeAsset from '@/assets/icons/benchmark-app/download-app-qr-code.svg'

const breadcrumbs = [{ name: 'Tools' }, { name: 'Benchmark App' }]

const { $browser } = useNuxtApp()

const storeLinks = {
  appStoreLink:
    'https://apps.apple.com/gb/app/benchmark-mineral-intelligence/id6475570691',
  googlePlayStoreLink:
    'https://play.google.com/store/apps/details?id=com.benchmark.prod&pcampaignid=web_share',
}

const redirectToStore = () => {
  if ($browser.isApple()) {
    window.open(storeLinks.appStoreLink, '_blank')
    return
  }
  window.open(storeLinks.googlePlayStoreLink, '_blank')
}

const bannerImages = {
  desktop:
    'https://res.cloudinary.com/benchmarkminerals/image/upload/c_scale,f_auto,h_1800,q_auto/v1729538008/assets/benchmark-app/banner_bm_app_pmmsgg.png',
  tablet:
    'https://res.cloudinary.com/benchmarkminerals/image/upload/c_scale,f_auto,h_1050,q_auto/v1729539974/assets/benchmark-app/banner_tablet_mjdjju.png',
}

const phoneCards = ref<CarouselMobileCard[]>([
  {
    title: 'Interactive Price Graphs',
    description:
      'Visualise historical and real-time Price Assessments with dynamic graphs and charts that allow you to monitor pricing trends',
    src: 'https://res.cloudinary.com/benchmarkminerals/image/upload/c_scale,f_auto,h_1800,q_auto/v1730124243/assets/benchmark-app/03_jdtsvk.png',
  },
  {
    title: 'Latest News and Analysis',
    description:
      "Don't miss the latest developments shaping global energy transition supply chains. Browse articles, videos, data visualisations and analysis all in one place",
    src: 'https://res.cloudinary.com/benchmarkminerals/image/upload/c_scale,f_auto,h_1800,q_auto/v1730124243/assets/benchmark-app/01_k62eid.png',
  },
  {
    title: 'Discover Upcoming Events',
    description:
      "Keep up to date with Benchmark's upcoming in-person and online events. Explore event locations, agendas, guest speakers and more",
    src: 'https://res.cloudinary.com/benchmarkminerals/image/upload/c_scale,f_auto,h_1800,q_auto/v1730124243/assets/benchmark-app/02_upfmva.png',
  },
  {
    title: 'Stay up to date ',
    description:
      'Opt in to receive push notifications directly to your mobile phone ensuring you stay up to date with market-specific price data',
    src: 'https://res.cloudinary.com/benchmarkminerals/image/upload/c_scale,f_auto,h_1800,q_auto/v1730124245/assets/benchmark-app/04_uws46g.png',
  },
])

const ourAppProvidesCards = [
  {
    title: 'Real-time Supply Chain Prices',
    description:
      'Instant access to Benchmark’s IOSCO assured prices anytime, anywhere. View existing price data, build charts and read the latest market commentary at the touch of a button',
    icon: RealTimeSupplyIcon,
  },
  {
    title: 'Comprehensive Market Insights',
    description:
      'Browse breaking news and detailed analysis powered by our proprietary data',
    icon: ComprehensiveIcon,
  },
  {
    title: 'Personalised Experience',
    description:
      'Customise your experience with tailored price alerts and notifications, keeping you updated on the latest developments shaping the markets relevant to you',
    icon: PersonalizedIcon,
  },
]
</script>

<template>
  <div class="relative bg-s-50">
    <Sidebar
      class="lg:hidden border-b border-white"
      :menus="toolsSubMenu"
      default-main-menu-name="Tools"
    />
    <header
      class="p-4 pb-6 lg:pt-9 lg:pb-0 lg:pr-0 lg:pl-10 text-white bg-s-900 mt-[47px] lg:mt-auto mb-11 2xl:px-0"
    >
      <div class="max-w-[1440px] mx-auto">
        <BreadcrumbWithoutSidebar
          :menu="breadcrumbs"
          class="mb-6 hidden items-center lg:flex"
        />
        <div class="flex">
          <div>
            <h1 class="text-h4 lg:text-h3 mb-3"> The Benchmark App </h1>
            <p class="mb-6 lg:text-lg">
              Your essential mobile resource for browsing energy transition
              supply chain data, news and analysis from Benchmark Mineral
              Intelligence
            </p>
            <Button
              class="text-sm w-full font-semibold lg:hidden"
              @click="redirectToStore"
            >
              Download App
            </Button>
            <div class="hidden gap-x-5 items-center lg:flex">
              <div class="h-[141px] w-[141px] p-2 bg-white">
                <QRCodeAsset class="h-full w-full" />
              </div>
              <p class="max-w-[258px]">
                Scan the QR code to download the
                <span class="text-p-500 font-semibold">Benchmark</span> app
              </p>
            </div>
          </div>
          <div class="hidden lg:block shrink-0">
            <img
              class="h-[356px] w-[361px] xl:hidden"
              :src="bannerImages.tablet"
              alt="banner"
            />
            <img
              class="h-[356px] w-[754px] hidden xl:block"
              :src="bannerImages.desktop"
              alt="banner"
            />
          </div>
        </div>
      </div>
    </header>
    <div
      class="relative mb-11 w-full lg:px-10 xl:px-12 2xl:px-0 xl:mb-[52px] max-w-[1440px] mx-auto"
    >
      <FeaturedMobileCarousel :phone-cards="phoneCards" />

      <div class="hidden lg:block">
        <h2 class="text-h3 mb-6">Our App Provides:</h2>
        <div class="flex flex-wrap justify-center gap-6 mb-6">
          <div
            v-for="card in ourAppProvidesCards"
            :key="card.title"
            class="p-6 flex bg-card rounded-xl max-w-[459px] xl:max-w-[464px] h-fit xl:h-auto"
          >
            <div class="pr-3 shrink-0">
              <component :is="card.icon" class="w-6 h-6 text-[#23252C]" />
            </div>
            <div class="border-l-[3px] border-p-500 pl-[7px] min-h-[92px]">
              <h3 class="text-h4 mb-2">{{ card.title }}</h3>
              <p>{{ card.description }}</p>
            </div>
          </div>
        </div>
      </div>

      <StoreButtonsSection
        class="px-4 lg:px-0 lg:mb-6"
        section-title="Download The Benchmark App"
        :app-store-link="storeLinks.appStoreLink"
        :google-play-store-link="storeLinks.googlePlayStoreLink"
      />

      <SubscriptionsAndAccessSection
        class="hidden lg:block"
        :phone-cards="phoneCards"
        title="Subscriptions and Access"
      />
    </div>
  </div>
</template>
